import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

import * as bootstrapOptions from 'bootstrap';

declare const bootstrap: any;

@Directive({
  standalone: true,
  selector: '[appSetTooltipDirective]',
})
export class SetTooltipDirective implements AfterContentInit, OnDestroy {
  @Input()
  tooltipOptions?: Partial<bootstrapOptions.Tooltip.Options>;

  constructor(private el: ElementRef) {}

  public ngAfterContentInit(): void {
    const element: HTMLElement = this.getElement();
    this.el.nativeElement = new bootstrap.Tooltip(element, this.tooltipOptions);
  }

  public ngOnDestroy(): void {
    this.el.nativeElement.hide();
  }

  private getElement(): HTMLElement {
    let element = this.el;

    if (this.el.nativeElement) {
      element = this.el.nativeElement;
    }
    return element as unknown as HTMLElement;
  }
}
