<app-modal
  modalBodyClass="modal-xs"
  [id]="userAddressWalletModalId"
  [closeOnBgClick]="false"
>
  <form [formGroup]="userWalletAddresForm" (ngSubmit)="submitAddresWalletForm()">
    <div class="d-flex flex-column connect-wallet-container">
      <div class="d-flex justify-content-between connect-wallet-header">
        <span class="connect-wallet-title primary ps-1">Usar direccion</span>
        <i class="bi bi-x-lg primary pointer pe-1" (click)="closeModal()"></i>
      </div>
      <div class="d-flex flex-column connect-wallet-body flex-fill">
        <input
          formControlName="walletId"
          class="form-control wallet-address"
          placeholder="Dirección de la wallet"
        />
        @if (f.walletId.invalid && f.walletId.touched) {
          <div class="text-danger mt-1">
            <span>Ingresa una dirección válida.</span>
          </div>
        }
        <div class="mt-4">
          <span>Red</span>
          <div class="dropdown dropdown-selection mt-2">
            <button
              class="d-flex align-items-center justify-content-between btn btn-secondary dropdown-toggle payment-method-item w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="primary me-2">{{ f.network.value?.customName }}</span>
            </button>
            <ul
              class="dropdown-menu option-name pointer black-scroll-bar w-100"
              aria-labelledby="dropdownMenuButton1"
            >
              @for (walletOption of networkOptions; track $index) {
                <li>
                  <div
                    class="dropdown-item"
                    (click)="f.network.setValue(walletOption)"
                    [ngClass]="{
                      selected: f.network.value?.customName === walletOption.customName
                    }"
                  >
                    {{ walletOption.customName }}
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
        <div class="disclaimer-container">
          <p class="disclaimer-text">
            Verifica bien la dirección antes de continuar. Pocket no asumirá ninguna
            responsabilidad si la información proporcionada es errónea.
          </p>
        </div>
      </div>
      <div class="method-modal-footer w-100 text-end">
        <button class="btn btn-primary" type="submit">
          <span>conectar</span>
        </button>
      </div>
    </div>
  </form>
</app-modal>
