import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ConnectWalletModalComponent,
  ConnectWalletStep,
} from '../connect-wallet-modal/connect-wallet-modal.component';
import { BlockchainService } from '../../../../core/services/blockchain/blockchain.service';
import { WalletBasicInfo } from '../../../../core/services/blockchain/blockchain.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { heartBeatOnEnterAnimation } from 'angular-animations';
import { SetTooltipDirective } from '../../../directives/set-tooltip.directive';
import { UserAddressWalletModalComponent } from '../user-address-wallet-modal/user-address-wallet-modal.component';
import { AddWalletOptionEnum } from './connect-wallet.model';

@UntilDestroy()
@Component({
  selector: 'app-connect-wallet',
  standalone: true,
  imports: [
    ConnectWalletModalComponent,
    SetTooltipDirective,
    UserAddressWalletModalComponent,
  ],
  templateUrl: './connect-wallet.component.html',
  styleUrl: './connect-wallet.component.scss',
  animations: [heartBeatOnEnterAnimation()],
})
export class ConnectWalletComponent implements OnInit {
  @ViewChild(ConnectWalletModalComponent)
  public connectWalletModal!: ConnectWalletModalComponent;

  @ViewChild(UserAddressWalletModalComponent)
  public userAddressWalletModalComponent!: UserAddressWalletModalComponent;

  @Input()
  public showWalletInfo = true;

  @Input()
  public showDisconnectButton = true;

  @Input()
  public showEditButton = false;

  public connectWalletStep = ConnectWalletStep;

  public walletBasicInfo: WalletBasicInfo | undefined;

  public userManuallyConnected = false;

  public addWalletOptionsList: AddWalletOptionEnum[] = [
    AddWalletOptionEnum.Connect,
    AddWalletOptionEnum.UseAddres,
  ];

  constructor(private blockchainService: BlockchainService) {}

  public async ngOnInit() {
    this.blockchainService.blockChainBasicInfo$.pipe(untilDestroyed(this)).subscribe({
      next: (walletInfo) => {
        this.walletBasicInfo = walletInfo?.walletBasicInfo;
        this.userManuallyConnected = walletInfo?.userTriggered || false;
        if (walletInfo?.userTriggered) {
          walletInfo.userTriggered = false;
          setTimeout(() => {
            this.userManuallyConnected = false;
          }, 0);
        }
      },
      error: () => (this.walletBasicInfo = undefined),
    });
  }

  public addWalletByOption(walletOption: AddWalletOptionEnum): void {
    if (walletOption === AddWalletOptionEnum.Connect) {
      this.openConnectWalletProviderModal();
    } else {
      this.openAdressWalletModal();
    }
  }

  public setAddressWalletInfo(walletBasicInfo: WalletBasicInfo) {
    this.blockchainService.updateBasicInfoWithDefaults(true, walletBasicInfo);
    this.showWalletInfo = true;
  }

  public openAdressWalletModal(): void {
    this.userAddressWalletModalComponent.openModal();
  }

  public openConnectWalletProviderModal(): void {
    this.connectWalletModal.openModal();
  }

  public disconnectWallet(): void {
    this.blockchainService.disconnect('Se desconectó correctamente de Metamask');
  }
}
