import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../modal/service/modal.service';
import { ModalComponent } from '../modal/modal.component';
import { BlockchainService } from '../../../../core/services/blockchain/blockchain.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlockchainNetwork } from './user-address-wallet.model';
import { ethers } from 'ethers';
import {
  POLYGON_MAINNET_CHAIN_ID,
  TRON_TRC_20,
  WalletBasicInfo,
} from '../../../../core/services/blockchain/blockchain.model';
import { CommonModule } from '@angular/common';

export const USER_ADDRESS_WALLET_MODAL_ID = 'user-address-wallet-modal-id';

@UntilDestroy()
@Component({
  selector: 'app-user-address-wallet-modal',
  standalone: true,
  imports: [ModalComponent, CommonModule, ReactiveFormsModule],
  templateUrl: './user-address-wallet-modal.component.html',
  styleUrl: './user-address-wallet-modal.component.scss',
})
export class UserAddressWalletModalComponent implements OnInit {
  @Input()
  public showConnectedProviderOption = false;

  @Output()
  public walletInfoEvent = new EventEmitter<WalletBasicInfo>();

  public userAddressWalletModalId = USER_ADDRESS_WALLET_MODAL_ID;

  public isWalletConnected = false;

  public networkOptions: BlockchainNetwork[] = [
    {
      customName: 'Tron (TRC20)',
      network: new ethers.Network('Tron (TRC20)', TRON_TRC_20),
    },
    {
      customName: 'Polygon',
      network: new ethers.Network('matic', POLYGON_MAINNET_CHAIN_ID),
    },
  ];

  public userWalletAddresForm = this.fb.group({
    walletId: this.fb.control<string | undefined>(undefined, [
      Validators.required,
      Validators.minLength(34),
      Validators.maxLength(42),
    ]),
    network: this.fb.control<BlockchainNetwork>(this.networkOptions[0], [
      Validators.required,
    ]),
  });

  constructor(
    private modalService: ModalService,
    private blockchainService: BlockchainService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.blockchainService.isConnected$
      .pipe(untilDestroyed(this))
      .subscribe(async (connected) => {
        this.isWalletConnected = connected;
      });
  }

  public get f() {
    return this.userWalletAddresForm.controls;
  }

  public submitAddresWalletForm(): void {
    this.userWalletAddresForm.markAllAsTouched();
    if (this.userWalletAddresForm.valid) {
      const walletInfo: WalletBasicInfo = {
        walletId: this.f.walletId.value || '',
        network: this.f.network.value!.network,
      };
      this.walletInfoEvent.emit(walletInfo);
      this.closeModal();
    }
  }

  public closeModal(): void {
    this.modalService.close(this.userAddressWalletModalId);
  }

  public openModal(): void {
    this.modalService.open(this.userAddressWalletModalId);
  }
}
