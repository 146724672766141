@if (walletBasicInfo && showWalletInfo) {
  <div
    class="d-flex justify-content-end"
    [@heartBeatOnEnter]
    [@.disabled]="!userManuallyConnected"
  >
    <div
      class="d-flex flex-grow-0 wallet-connected-container align-items-center text-end"
    >
      <img src="/assets/img/metamask-sm.svg" class="me-1" />
      <span
        class="mx-2 secondary-400 pointer"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-placement="bottom"
        data-bs-custom-class="inline-tooltip-text"
        appSetTooltipDirective
        [attr.data-bs-title]="walletBasicInfo.walletId"
        >{{ walletBasicInfo.walletId.slice(0, 9) }}...</span
      >
      @if (showDisconnectButton) {
        <span class="primary pointer fw-bold" (click)="disconnectWallet()"
          >Desconectar</span
        >
      }
      @if (showEditButton) {
        <span class="primary pointer fw-bold" (click)="openAdressWalletModal()"
          >Editar</span
        >
      }
    </div>
  </div>
} @else {
  @if (showWalletInfo) {
    <div>
      <button
        class="mx-2 secondary-400"
        type="button"
        class="btn btn-primary btn-connect-wallet"
        (click)="openConnectWalletProviderModal()"
      >
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/wallet.svg" />
          <span class="ms-3 me-3">Conectar wallet</span>
        </div>
      </button>
    </div>
  } @else {
    <div class="dropdown dropdown-selection">
      <button
        class="d-flex align-items-center justify-content-between btn btn-secondary dropdown-toggle payment-method-item w-100"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span class="primary me-2">Agregar wallet</span>
      </button>
      <ul
        class="dropdown-menu option-name pointer black-scroll-bar w-100"
        aria-labelledby="dropdownMenuButton1"
      >
        @for (walletOption of addWalletOptionsList; track $index) {
          <li>
            <div class="dropdown-item" (click)="addWalletByOption(walletOption)">
              {{ walletOption }}
            </div>
          </li>
        }
      </ul>
    </div>
  }
}

<app-connect-wallet-modal
  [currentStep]="connectWalletStep.INFO"
  (walletConectedEvent)="showWalletInfo = true"
></app-connect-wallet-modal>

<app-user-address-wallet-modal
  (walletInfoEvent)="setAddressWalletInfo($event)"
></app-user-address-wallet-modal>
